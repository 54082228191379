<script>
import moment from 'moment'
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'

export default {
  name: 'activitys',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/farmActivity/page',
      current: 1,
      paramsValue: {
        publishingType: 0,
        groupType: '0',
      },
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input',
        },
        {
          label: '',
          type: 'text',
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left',
          sorter: (a, b) => a.name.length - b.name.length,
          customRender: (text, records) => {
            const src = records.imgUrl.split(',')[0]
            return (
              <div class="product-order-item-copy">
                <img src={src} />
                <div>{text}</div>
              </div>
            )
          },
          //   onExport: (text, records) => {
          //     const src = records.imgUrl.split(',')[0]
          //     return `${src} / ${text}`
          //   }
        },
        {
          dataIndex: 'palmDisplayList',
          title: '发布平台',
          align: 'left',
          type: 'badge',
          onExport: (text, records) => {
            return records.palmDisplayList.map((e) => e.appName)
          },
          customRender: function (text, records) {
            if (text.length > 1) {
              return records.palmDisplayList.map((e) => e.appName + ' ')
            } else {
              return records.palmDisplayList.map((e) => e.appName)
            }
          },
        },
        {
          dataIndex: 'sort',
          title: '排序',
          align: 'left',
          sorter: (a, b) => a.sort - b.sort,
        },
        {
          dataIndex: 'createTime',
          title: '创建时间',
          align: 'left',
          sorter: (a, b) => moment(a.createTime).diff(moment(b.createTime)),
        },
        {
          dataIndex: 'endTime',
          title: '下架时间',
          align: 'left',
          sorter: (a, b) => moment(a.endTime).diff(moment(b.endTime)),
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          yesText: '上架',
          noText: '下架',
          onExport: (records) => {
            return ['上架', '下架'][records]
          },
          filters: [
            {
              text: '上架',
              value: '0',
            },
            {
              text: '下架',
              value: '1',
            },
          ],
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data === '0' ? '上架' : '下架',
              color: data === '0' ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'a8',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                name: '报名',
                onClick: () => {
                  this.$router.push('/publicInfo/toSignUp?id=' + records.id)
                },
              },
              {
                name: records.upDown === '0' ? '下架' : '上架',
                onClick: () => {
                  api.command.editGET.call(this, {
                    url: `/api/farmActivity/${records.upDown === '0' ? 'down' : 'up'}?id=${records.id}`,
                  })
                },
              },
              {
                name: '编辑',
                onClick: () => {
                  this.$router.push({
                    path: '/publicInfo/activitysDetail',
                    query: { id: records.id },
                  })
                },
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认删除？',
                onClick: () =>
                  api.command.del.call(this, {
                    url: `/farmActivity?id=${records.id}`,
                  }),
              },
            ]
          },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/publicInfo/activitysDetail'),
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量上架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/farmActivity/upBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量下架',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit.call(this, {
                  url: '/farmActivity/downBatch',
                  params: { idList },
                })
              },
            },
            {
              name: '批量删除',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.delPut.call(this, {
                  url: '/farmActivity/deleteBatch',
                  params: { idList },
                })
              },
            },
          ],
        },
      ]
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
